<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.keywords' placeholder='请输入姓名/身份证号' clearable @clear='getStudentLearningRecordPage' @keyup.enter.native="getStudentLearningRecordPage" @input='() =>(filters.keywords = filters.keywords.replace(/\s/g,""))'></el-input>
                    </el-form-item>
                    <el-form-item label='教材名称：'>
                        <el-select v-model="filters.teachingMaterialName" placeholder="请选择" clearable @change='getStudentLearningRecordPage'>
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label='行业名称：'>
                        <el-select v-model="filters.tradeID" placeholder="请选择" clearable @change='getStudentLearningRecordPage'>
                            <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label='培训机构：'>
                        <el-select v-model="filters.agencyID" placeholder="请选择" clearable @change='getStudentLearningRecordPage'>
                            <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        :data="tableData"
        highlight-current-row
        v-loading='listLoading'
        :cell-style="cellStyle"
        style="width: 100%">
            <el-table-column  type="index" label="序号" width="55" align='center'></el-table-column>
            <el-table-column prop='Name' label='姓名' width='100' align='center'></el-table-column>
            <el-table-column prop='PhoneNumber' label='手机号' width='120' align='center'></el-table-column>
            <el-table-column prop='CardNo' label='身份证号' width='180' align='center'></el-table-column>
            <el-table-column prop='AgencyName' label='培训机构' width='' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='TeachingMaterialName' label='教材名称' width='' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='TeachingVideoName' label='视频名称' width='' align='center' show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop='TradeName' label='行业名称' width='120' align='center' show-overflow-tooltip></el-table-column> -->
            <el-table-column prop='StartLearningTime' label='学习打卡开始时间' width='165' align='center' show-overflow-tooltip>
                <template slot-scope="scope">
                     {{dateFormat('YYYY-mm-dd HH:MM:SS',scope.row.StartLearningTime)}}
                </template>
            </el-table-column>
            <el-table-column prop='EndLearningTime' label='学习打卡结束时间' width='165' align='center' >
                <template slot-scope="scope">
                     {{scope.row.EndLearningTime ? dateFormat('YYYY-mm-dd HH:MM:SS',scope.row.EndLearningTime) :null}}
                </template>
            </el-table-column>
            <el-table-column prop='LearningDurationText' label='学习时长' width='90' align='center'>
                <template slot-scope="scope">
                    {{replaceString(scope.row.LearningDurationText)}}
                </template>
            </el-table-column>
            <el-table-column prop='PhotoCount' label='自拍数量' width='90' align='center' fixed="right">
                <template slot-scope='scope'>
                    <el-button type='text' @click='recordDetail(scope.row.ID,scope.row.StudentLearningAccountID)'>{{scope.row.PhotoCount}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
        <!-- 自拍数量 -->
        <el-dialog
        title="自拍记录"
        :close-on-click-modal='false'
        :visible.sync="dialogVisible"
        width="50%">
        <el-table
        border
        :data="recordData"
        highlight-current-row
        v-loading='recordLoading'
        style="width: 95%">
            <el-table-column  type="index" label="序号" width="55" align='center'></el-table-column>
            <el-table-column prop='AttendanceType' label='类型' width='' align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.AttendanceType == 0?"primary":scope.row.AttendanceType == 1?"warning":"success"'>
                        {{scope.row.AttendanceType==0?'学习前':scope.row.AttendanceType==1?'学习中':'学习结束前'}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='CreateTime' label='自拍时间' width='' align='center' :formatter='formatCreateTime' show-overflow-tooltip></el-table-column>
            <el-table-column prop='AttendancePhotoUrl' label='照片' width='' align='center'>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.AttendancePhotoUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.AttendancePhotoUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
        @size-change="recordHandleSizeChange"
        @current-change="recordHandleCurrentChange"
        :current-page="recordPages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="recordPages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="recordPages.dataCount">
        </el-pagination>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import {
    getStudentLearningRecordPage,
    getDataDictionaryList,
    getAgencyInfoListPage,
    getTeachingMaterialTree,
    getStudentLearningAttendancePage
} from '@/api/api'
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList:[],
            filters:{
                keywords:'',
                tradeID:'',
                teachingMaterialName:'',
                agencyID:''
            },
            options:[],
            options1:[],
            options2:[],
            tableData:[],
            listLoading:false,
            pages:{
                pageSize:20,
                pageIndex:1,
                dataCount:0
            },
            dialogVisible:false,
            // 自拍数据
            recordData:[],
            recordLoading:false,
            recordPages:{
                pageIndex:1,
                pageSize:10,
                dataCount:0
            }
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        cellStyle(){
            return 'height:38px;'
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },
        // 查询
        getStudentLearningRecordPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 获取列表数据
        getData(){
            this.listLoading = true
            var params = {
                agencyID:this.filters.agencyID,
                tradeID:this.filters.tradeID,
                teachingMaterialName:this.filters.teachingMaterialName,
                keywords:this.filters.keywords,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            getStudentLearningRecordPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{
                this.listLoading = false
                this.$message.error(res.data.Message)
            })
        },
        // 获取行业、岗位数据
        // getDictionaryData(){
        //     var params = {
        //         dataType:1
        //     }
        //     getDataDictionaryList(params).then(res => {
        //         if(res.data.Success){
        //             var data = res.data.Response
        //             this.options1 = data.map(item =>({
        //                 label:item.Name,
        //                 value:item.ID
        //             }))
        //         }else{return}
        //     }).catch(() => {})
        // },
        // 获取培训机构数据
        getAgencyData(){
            getAgencyInfoListPage().then(res => {
                if(res.data.Success){
                    var data = res.data.Response.Data
                    this.options2 = data.map(item => ({
                        label:item.Name,
                        value:item.ID
                    }))
                }else{return}
            }).catch(() => {})
        },
        // 获取教材数据
        getTeachingData(){
            var params = {
                parentId:0,
                isEnable:true
            }
            getTeachingMaterialTree(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    this.options = data.map(item => ({
                        label:item.Name,
                        value:item.ID
                    }))
                }else{return}
            }).catch(() => {})
        },
        // 时间转换问题
        dateFormat(format,date){
            let ret = ''
            date = new Date(date)
            const option = {
                'Y+':date.getFullYear().toString(),  //
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        },
        replaceString(val){
            if(val){
                var V = val.indexOf(".")
                return val.substring(0,V)
            }
        },
        // 自拍详情
        recordDetail(id,studentLearningAccountID){
            // console.log(id)
            // console.log(id)
            // studentLearningRecordID
            
            var params = {
                studentLearningRecordID: id,
                studentLearningAccountID: studentLearningAccountID,
                pageIndex:this.recordPages.pageIndex,
                pageSize:this.recordPages.pageSize
            }
            this.dialogVisible = true
            this.recordLoading = true
            getStudentLearningAttendancePage(params).then(res => {
                this.recordLoading = false
                if(res.data.Success){
                    this.recordData = res.data.Response.Data
                    this.recordPages.dataCount = res.data.Response.DataCount
                }else{
                    this.recordLoading = false
                }
            })

        },
        // 自拍分页处理
        recordHandleSizeChange(value){
            this.recordPages.pageSize = value
            this.recordDetail(id)
        },
        recordHandleCurrentChange(value){
            this.recordPages.pageIndex = value
            this.recordDetail(id)
        },

    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }
        next()
    },
    created(){
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
        // this.getDictionaryData()
        this.getAgencyData()
        this.getTeachingData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>